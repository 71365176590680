import './PageData.scss';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
// import Img from 'gatsby-image/withIEPolyfill';
import Buttons from '../../Buttons/Buttons';
// import menuIcons from '../../Layout/MainMenu/menuIcons';
import Icon from '../../../helpers/icon';

export const PageInfoData = graphql`
  fragment PageInfoData on PageInfoBlock {
    id
    blockid
    description
    video
    image
    alt
    button {
      blank
      color
      exception
      link
      noRel
      size
      text
    }
    card {
      title
      popUpContent
      cardButton {
        blank
        color
        exception
        link
        noRel
        size
        text
      }
    }
    # localImage {
    #   childImageSharp {
    #     fluid {
    #       GatsbyImageSharpFluid
    #     }
    #   }
    # }
  }
`;

const LeftBody = ({ description, button }) => {
  return (
    <>
      <div className="textBody">
        <span className="saga-video-box-text" dangerouslySetInnerHTML={{ __html: description }} />
        <Buttons {...button} />
      </div>
    </>
  );
};

const RightBody = ({ card, video, localImage, alt, openModal }) => {
  const url = video.replace('watch?v=', 'embed/');
  return (
    <>
      {/* {video ? (
          <iframe title="video" width="100" height="100" src={url} />
        ) : localImage.childImageSharp ? (
          <Img className="imgPage" fluid={localImage.childImageSharp.fluid} alt={alt} />
        ) : null} */}
      {card.title ? (
        <div className="card">
          {card.title}
          {card.cardButton.cta ? (
            <Buttons {...card.cardButton} />
          ) : (
            <Buttons {...card.cardButton} onClick={openModal} />
          )}
        </div>
      ) : null}
    </>
  );
};

const PopUpData = ({ onClose, card: { popUpContent } }) => {
  return (
    <div className="pagedata-popup">
      <button className="popup-close" onClick={onClose} type="button">
        {/* <Icon
          icon="cross"
          width="25"
          height="25"
          class="is-center"
          color="#ffffff"
          iconSet={menuIcons}
        /> */}
      </button>
      <div className="popup-text">
        <div dangerouslySetInnerHTML={{ __html: popUpContent }} />
      </div>
    </div>
  );
};

const PageDataContainer = (data) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="leftBody">
        <LeftBody {...data} />
      </div>
      <div className="rightBody">
        <RightBody
          {...data}
          openModal={() => {
            setOpen(true);
          }}
        />
      </div>
      {isOpen ? <PopUpData onClose={() => setOpen(false)} {...data} /> : null}
    </>
  );
};

export default PageDataContainer;

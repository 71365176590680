import React from 'react';
import lokiSet from './slider-icons';
import Icon from '../../../helpers/icon';
import styles from './SliderSettings.module.scss';

const SampleNextArrow = properties => {
  const { className, style, onClick } = properties;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        zIndex: '100',
        right: '20px',
        background: 'white',
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-right" width="35" height="35" class="icon-arrow" iconSet={lokiSet} />
    </div>
  );
};

const SamplePrevArrow = properties => {
  const { className, style, onClick } = properties;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        zIndex: '100',
        left: '20px',
        background: 'white',
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-left" width="35" height="35" class="icon-arrow" iconSet={lokiSet} />
    </div>
  );
};

const gallerySettings = (props, element) => {
  const type = props;
  const dots = false;
  switch (type) {
    case 'oneItem':
      return {
        centerMode: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        speed: 500,
        dots: true,
        dotsClass: `${styles.slickDots}`,
      };
    case 'infinitieModal':
      return {
        initialSlide: element,
        centerMode: true,
        dots: false,
        infinite: true,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      };
    case 'tooLong':
      /**
       * Custom paging:
       * Se utiliza para limitar el numero de bolitas,
       * en caso de tener mas de un slider por pàgina nos da problemas,
       * de manera que hemos añadido como ID en el WindosStar el slideCount.
       * Pero esto solo funciona de manera temporal, ya que si tuvieramos dos sliders con el mismo numero de items,
       * nos encontrariamos con el mismo problema.
       * De manera que deberemos mejorar esta funcion en un futuro cercano.
       *
       * TO DO!!
       */
      return {
        customPaging(i) {
          const { currentSlide, slideCount } = this;
          const window = 5;
          let windowStart = 0;
          if (currentSlide === 0) {
            if (i === 0) {
              localStorage.setItem(`windowStart${slideCount}`, windowStart);
            }
          } else {
            windowStart = parseInt(localStorage.getItem(`windowStart${slideCount}`));
          }
          if (currentSlide - windowStart === 5) {
            windowStart += 1;
            if (windowStart + window === slideCount) windowStart -= 1;
            localStorage.setItem(`windowStart${slideCount}`, windowStart);
          }
          if (currentSlide - windowStart === 0) {
            windowStart -= 1;
            if (windowStart < 0) windowStart = 0;
            localStorage.setItem(`windowStart${slideCount}`, windowStart);
          }
          if (i > windowStart + window)
            return <span className={styles.spam} sytle="display:none" />;
          if (i < windowStart) return <span className={styles.spam} sytle="display:none" />;

          if (i === currentSlide) {
            return <div className={styles.slickActiveDot} />;
          }
          return <div className={styles.slickDisabled} />;
        },

        centerMode: false,
        infinite: false,
        centerPadding: '60px',
        slidesToShow: 1,
        speed: 500,
        dots: true,
        dotsClass: `${styles.slickDotsCustom}`,
        lastSlide: 0,
      };
    case 'normal':
      return {
        centerMode: false,
        infinite: false,
        speed: 500,
        dots: true,
        dotsClass: `${styles.slickDots}`,
        nextArrow: dots ? '' : <SampleNextArrow />,
        prevArrow: dots ? '' : <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 459,
            settings: {
              slidesToShow: 1.2,
            },
          },
          {
            breakpoint: 505,
            settings: {
              slidesToShow: 1.3,
            },
          },
          {
            breakpoint: 539,
            settings: {
              slidesToShow: 1.4,
            },
          },
          {
            breakpoint: 569,
            settings: {
              slidesToShow: 1.5,
            },
          },
          {
            breakpoint: 611,
            settings: {
              slidesToShow: 1.6,
            },
          },
          {
            breakpoint: 689,
            settings: {
              slidesToShow: 1.7,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 1.9,
            },
          },
          {
            breakpoint: 827,
            settings: {
              slidesToShow: 2.1,
            },
          },
          {
            breakpoint: 908,
            settings: {
              slidesToShow: 2.2,
            },
          },
          {
            breakpoint: 967,
            settings: {
              slidesToShow: 2.4,
            },
          },
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 2.6,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2.8,
            },
          },
          {
            breakpoint: 1120,
            settings: {
              slidesToShow: 2.9,
            },
          },
          {
            breakpoint: 1165,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1240,
            settings: {
              slidesToShow: 3.2,
            },
          },
          {
            breakpoint: 1270,
            settings: {
              slidesToShow: 3.4,
            },
          },
          {
            breakpoint: 1349,
            settings: {
              slidesToShow: 3.5,
            },
          },
        ],
      };
    case 'normalNoArrows':
      return {
        centerMode: false,
        infinite: false,
        speed: 500,
        dots: true,
        dotsClass: `${styles.slickDots}`,
        arrows: false,
        responsive: [
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 1.1,
            },
          },
          {
            breakpoint: 459,
            settings: {
              slidesToShow: 1.2,
            },
          },
          {
            breakpoint: 505,
            settings: {
              slidesToShow: 1.3,
            },
          },
          {
            breakpoint: 539,
            settings: {
              slidesToShow: 1.4,
            },
          },
          {
            breakpoint: 569,
            settings: {
              slidesToShow: 1.5,
            },
          },
          {
            breakpoint: 611,
            settings: {
              slidesToShow: 1.6,
            },
          },
          {
            breakpoint: 689,
            settings: {
              slidesToShow: 1.7,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 1.9,
            },
          },
          {
            breakpoint: 827,
            settings: {
              slidesToShow: 2.1,
            },
          },
          {
            breakpoint: 908,
            settings: {
              slidesToShow: 2.2,
            },
          },
          {
            breakpoint: 967,
            settings: {
              slidesToShow: 2.4,
            },
          },
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 2.6,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2.8,
            },
          },
          {
            breakpoint: 1120,
            settings: {
              slidesToShow: 2.9,
            },
          },
          {
            breakpoint: 1165,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1240,
            settings: {
              slidesToShow: 3.2,
            },
          },
          {
            breakpoint: 1270,
            settings: {
              slidesToShow: 3.4,
            },
          },
          {
            breakpoint: 1349,
            settings: {
              slidesToShow: 3.5,
            },
          },
        ],
      };
    case 'infinite':
      return {
        centerMode: false,
        infinite: true,
        centerPadding: '60px',
        slidesToShow: 3.7,
        speed: 500,
        dots: true,
        dotsClass: `${styles.slickDots}`,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 459,
            settings: {
              slidesToShow: 1.2,
            },
          },
          {
            breakpoint: 505,
            settings: {
              slidesToShow: 1.3,
            },
          },
          {
            breakpoint: 539,
            settings: {
              slidesToShow: 1.4,
            },
          },
          {
            breakpoint: 569,
            settings: {
              slidesToShow: 1.5,
            },
          },
          {
            breakpoint: 611,
            settings: {
              slidesToShow: 1.6,
            },
          },
          {
            breakpoint: 689,
            settings: {
              slidesToShow: 1.7,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 1.9,
            },
          },
          {
            breakpoint: 827,
            settings: {
              slidesToShow: 2.1,
            },
          },
          {
            breakpoint: 908,
            settings: {
              slidesToShow: 2.2,
            },
          },
          {
            breakpoint: 967,
            settings: {
              slidesToShow: 2.4,
            },
          },
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 2.6,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2.8,
            },
          },
          {
            breakpoint: 1120,
            settings: {
              slidesToShow: 2.9,
            },
          },
          {
            breakpoint: 1165,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1240,
            settings: {
              slidesToShow: 3.2,
            },
          },
          {
            breakpoint: 1270,
            settings: {
              slidesToShow: 3.4,
            },
          },
          {
            breakpoint: 1349,
            settings: {
              slidesToShow: 3.5,
            },
          },
        ],
      };

    default:
      return {
        centerMode: false,
        infinite: false,
        centerPadding: '60px',
        dotsClass: 'dots-class',
        slidesToShow: 1,
        speed: 500,
        dots: true,
      };
  }
};

export default gallerySettings;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import { injectIntl } from 'react-intl';
import { graphql } from 'gatsby';
import PromoSettings from '../../SliderSettings/SliderSettings';
import textSize from '../../../../helpers/textSize';
import styles from './promotions.module.scss';
import GlobalCard from '../Card/Cards';

export const CardsData = graphql`
  fragment CardsData on CardsBlock {
    blockid
    isSlider
    position
    subBlock {
      position
      description
      located
      cardStyle
      titleTag
      titleStyle
      image
      altImage
      title
      additionalInfo
      filterValue
      ButtonsCard {
        cta
        ctaText
        size
        color
        cta2
        ctaText2
        size2
        color2
      }
      popUpContent
      localImage {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    # localImage {
    #   childImageSharp {
    #     fluid {
    #       GatsbyImageSharpFluid
    #     }
    #   }
    # }
  }
`;

const CardMap = ({ subBlock, intl, filters, setCount }) => {
  const keywords = Object.keys(filters);
  let cont = 0;
  const passingBlocks = subBlock.map((element) => {
    if (keywords.length === 0) {
      setCount((cont += 1));
      return <GlobalCard {...element} key={element.id} />;
    }

    const passesFilters = keywords.every((kw) => element.filterValue.includes(filters[kw]));
    return passesFilters ? <GlobalCard {...element} key={element.id} /> : null;
  });

  return (
    passingBlocks || <div>{tt('No hay elementos que coincidan con tus filtros.', intl.locale)}</div>
  );
};

const SliderCardMap = ({ blocks, galSettings, filters, setCount }) => {
  const keywords = Object.keys(filters);
  let cont = 0;
  const passingBlocks = blocks.map((element) => {
    if (keywords.length === 0) {
      setCount((cont += 1));
      return <GlobalCard {...element} key={element.id} />;
    }
    const passesFilters = keywords.every((kw) => element.filterValue.includes(filters[kw]));
    return passesFilters ? <GlobalCard {...element} key={element.id} /> : null;
  });

  return (
    <Slider {...PromoSettings(galSettings)}>{passingBlocks}</Slider> || (
      <div>{tt('No hay elementos que coincidan con tus filtros.', intl.locale)}</div>
    )
  );
};

export const Cards = injectIntl(
  ({ intl, data: { subBlock, isSlider, bg, title }, filters, setCount }) => {
    const titleTag = 'h4';
    const titleStyle = 'h3';
    let lul = '';
    if (subBlock.length > 4 && subBlock.length <= 10) {
      lul = 'normalNoArrows';
    } else if (subBlock.length > 10) {
      lul = 'tooLong';
    } else {
      lul = 'normalNoArrows';
    }
    return (
      <div className={`${styles.globalCards} ${bg === true ? styles.bgGrey : null}`}>
        {title ? (
          <div className={styles.globalCardTitle}>{textSize(title, titleTag, titleStyle)}</div>
        ) : null}
        {!isSlider ? (
          <>
            <div className={`${styles.globalCardsContainer} hide-tablet`}>
              <CardMap {...{ subBlock, intl, filters, setCount }} />
            </div>
            <div className={`${styles.globalCardsContainer} hide-md-xl`}>
              <SliderCardMap
                blocks={subBlock}
                galSettings={lul}
                filters={filters}
                setCount={setCount}
              />
            </div>
          </>
        ) : (
          <div className={styles.globalCardsContainer}>
            <SliderCardMap
              blocks={subBlock}
              galSettings={lul}
              filters={filters}
              setCount={setCount}
            />
          </div>
        )}
      </div>
    );
  }
);

Cards.propTypes = {
  props: {
    isSlider: PropTypes.bool,
    blocks: PropTypes.array,
    bg: PropTypes.bool,
    title: PropTypes.string,
  },
};

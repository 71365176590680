const magniIcons = {
  IcoMoonType: 'selection',
  icons: [
    {
      icon: {
        paths: [
          'M1050.511 404.298h-172.324c-26.511 0-46.395-16.57-53.023-39.767l-9.942-53.023c0 0 0 0 0 0-6.628-36.453-29.825-66.278-62.964-89.476 0 0 0 0 0 0l-205.463-145.812c0 0-3.314-3.314-3.314-3.314-56.337-33.139-102.731-29.825-132.557-19.883l-215.405 56.337c-26.511 6.628-43.081 33.139-36.453 62.964 6.628 26.511 36.453 43.081 62.964 36.453l215.405-56.337c0 0 3.314 0 3.314 0 9.942-3.314 26.511 0 43.081 9.942l92.79 66.278-169.010 135.871c-43.081 36.453-46.395 96.104-16.57 135.871l119.301 159.068h-357.903c-29.825 0-53.023 23.197-53.023 49.709s23.197 49.709 53.023 49.709h367.845c36.453 0 69.592-16.57 86.162-43.081 19.883-26.511 23.197-62.964 6.628-96.104 0 0 0 0 0 0l-69.592-145.812 162.382-125.929 6.628 39.767c0 0 0 0 0 3.314 6.628 33.139 26.511 62.964 53.023 86.162 29.825 23.197 62.964 33.139 102.731 33.139h172.324c29.825 0 53.023-23.197 53.023-49.709 9.942-36.453-13.256-56.337-43.081-56.337zM841.735 202.149c59.65 0 106.045-46.395 106.045-99.417 0-56.337-46.395-99.417-106.045-99.417s-106.045 46.395-106.045 99.417c0 53.023 46.395 99.417 106.045 99.417zM222.032 947.78c0 0-23.197 39.767 9.942 62.964 29.825 23.197 62.964 6.628 79.534-6.628l205.463-202.149h-145.812l-149.126 145.812zM367.845 291.625l56.337-39.767h-424.181v49.709h351.275c6.628-3.314 9.942-3.314 16.57-9.942zM288.311 454.006h-288.311v49.709h304.88c-9.942-16.57-9.942-36.453-16.57-49.709zM311.508 351.275h-311.508v49.709h294.939c0-16.57 9.942-33.139 16.57-49.709z',
        ],
        width: 1104,
        isMulticolor: false,
        isMulticolor2: false,
        tags: ['FastPass'],
        defaultCode: 59667,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 52,
        order: 1051,
        ligatures: '',
        prevSize: 32,
        code: 59670,
        name: 'FastPass',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 51,
    },
    {
      icon: {
        paths: [
          'M181.267 181.267c54.062 0 101.764-41.342 101.764-92.224s-44.522-92.224-101.764-92.224-101.764 41.342-101.764 92.224c0 54.062 44.522 92.224 101.764 92.224zM248.050 213.068h-133.565c-60.422 0-111.304 44.522-111.304 101.764v235.329c0 41.342 28.621 76.323 66.783 92.224v295.752c0 44.522 41.342 82.683 89.043 82.683h44.522c47.702 0 89.043-38.161 89.043-82.683v-295.752c38.161-15.901 66.783-50.882 66.783-92.224v-235.329c0-54.062-50.882-101.764-111.304-101.764zM607.404 944.497h-143.106v34.981h178.087v-906.335h-34.981v871.354zM464.298 34.981v34.981h178.087v-34.981h-178.087z',
        ],
        width: 642,
        isMulticolor: false,
        isMulticolor2: false,
        tags: ['HeightRestriction'],
        defaultCode: 59668,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 53,
        order: 1052,
        ligatures: '',
        prevSize: 32,
        code: 59671,
        name: 'HeightRestriction',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 52,
    },
    {
      icon: {
        paths: [
          'M449.757 961.757c-114.447 0-208.816-94.369-208.816-208.816 0-76.298 40.157-140.549 100.392-178.698l-12.047-64.251c-88.345 44.173-148.58 136.533-148.58 242.949 0 150.588 120.471 271.059 271.059 271.059 148.58 0 271.059-122.478 271.059-271.059h-62.243c-2.008 114.447-96.376 208.816-210.824 208.816zM417.631 208.816c58.227 0 104.408-46.18 104.408-104.408s-46.18-104.408-104.408-104.408-104.408 46.18-104.408 104.408c2.008 58.227 48.188 104.408 104.408 104.408zM921.6 891.482l-52.204-261.020c-6.024-32.125-34.133-54.212-66.259-54.212h-210.824l-50.196-250.98c-12.047-56.22-66.259-92.361-122.478-82.322l-230.902 46.18c-22.086 4.016-40.157 22.086-44.173 44.173l-42.165 188.737c-6.024 30.118 12.047 62.243 44.173 68.267 4.016 0 8.031 2.008 12.047 2.008 26.102 0 50.196-18.071 56.22-44.173l34.133-150.588 92.361-18.071 50.196 250.98c10.039 50.196 54.212 84.329 102.4 84.329h257.004l42.165 206.808c6.024 32.125 34.133 54.212 66.259 54.212 4.016 0 8.031 0 14.055-2.008 32.125-10.039 56.22-46.18 48.188-82.322z',
        ],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ['ReducedMobility'],
        defaultCode: 59707,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 92,
        order: 1128,
        ligatures: '',
        prevSize: 32,
        code: 59712,
        name: 'ReducedMobility',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 91,
    },
    {
      icon: {
        paths: [
          'M59.284 0c-32.337 0-53.895 21.558-53.895 59.284v910.821c0 32.337 21.558 59.284 53.895 59.284h1234.189c32.337 0 53.895-21.558 53.895-59.284v-916.211c0-32.337-21.558-59.284-53.895-59.284h-1234.189zM1686.905 172.463l-280.253 172.463v339.537l280.253 172.463h113.179v-684.463h-113.179z',
        ],
        width: 1795,
        isMulticolor: false,
        isMulticolor2: false,
        tags: ['VideoRide'],
        defaultCode: 59671,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 56,
        order: 1055,
        ligatures: '',
        prevSize: 32,
        code: 59674,
        name: 'VideoRide',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 55,
    },
    {
      icon: {
        paths: [
          'M708.187 339.738c-129.196 0-239.252 100.486-239.252 224.897s105.271 224.897 239.252 224.897c129.196 0 239.252-100.486 239.252-224.897s-105.271-224.897-239.252-224.897zM1301.533 114.841h-416.299v-57.421c0-33.495-28.71-57.421-57.421-57.421h-239.252c-33.495 0-57.421 23.925-57.421 57.421v57.421h-416.299c-66.991 0-119.626 52.636-119.626 114.841v679.477c0 62.206 52.636 114.841 119.626 114.841h1186.692c66.991 0 119.626-52.636 119.626-114.841v-679.477c0-66.991-52.636-114.841-119.626-114.841zM708.187 909.159c-196.187 0-354.093-153.121-354.093-339.738s157.907-339.738 354.093-339.738c196.187 0 354.093 153.121 354.093 339.738 4.785 186.617-157.907 339.738-354.093 339.738z',
        ],
        width: 1421,
        isMulticolor: false,
        isMulticolor2: false,
        tags: ['PhotoRide'],
        defaultCode: 59669,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 54,
        order: 1053,
        ligatures: '',
        prevSize: 32,
        code: 59672,
        name: 'PhotoRide',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 53,
    },
    {
      icon: {
        paths: [
          'M742.4 361.244c-2.844-14.222-14.222-22.756-28.444-22.756h-489.244c-14.222 0-25.6 11.378-28.444 22.756l-42.667 204.8h628.622l-39.822-204.8zM332.8 304.356c0 5.689-2.844 14.222-2.844 22.756h278.756c0-8.533 0-14.222-2.844-22.756l159.289-164.978c17.067-19.911 17.067-48.356 0-68.267s-48.356-19.911-65.422 0l-139.378 145.067c-17.067-14.222-34.133-25.6-56.889-31.289 34.133-14.222 56.889-48.356 56.889-88.178 0-54.044-42.667-96.711-93.867-96.711s-93.867 42.667-93.867 96.711c0 39.822 22.756 73.956 56.889 88.178-19.911 5.689-39.822 17.067-56.889 31.289l-139.378-145.067c-17.067-19.911-48.356-19.911-65.422 0s-17.067 48.356 0 68.267l164.978 164.978zM719.644 688.356h42.667c14.222 0 22.756-11.378 22.756-22.756v-71.111h-628.622v71.111c0 14.222 11.378 22.756 22.756 22.756h42.667c-56.889 119.467-153.6 256-219.022 335.644h34.133c65.422-82.489 159.289-213.333 219.022-335.644h8.533c0 0 0 0 0 0h34.133c-8.533 17.067-14.222 34.133-22.756 48.356h398.222c-8.533-17.067-17.067-34.133-22.756-48.356h34.133c0 0 0 0 0 0h5.689c59.733 122.311 153.6 253.156 219.022 335.644h34.133c-71.111-82.489-170.667-216.178-224.711-335.644zM162.133 921.6c-14.222 22.756-28.444 42.667-42.667 59.733h702.578c-14.222-17.067-28.444-39.822-42.667-59.733h-617.244zM258.844 759.467c-8.533 17.067-17.067 31.289-25.6 48.356h477.867c-8.533-17.067-17.067-31.289-25.6-48.356h-426.667zM179.2 893.156h580.267c-11.378-19.911-25.6-39.822-36.978-59.733h-506.311c-11.378 19.911-22.756 39.822-36.978 59.733z',
        ],
        width: 939,
        isMulticolor: false,
        isMulticolor2: false,
        tags: ['TypeofRide'],
        defaultCode: 59670,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 55,
        order: 1054,
        ligatures: '',
        prevSize: 32,
        code: 59673,
        name: 'TypeofRide',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 54,
    },
    {
      icon: {
        paths: [
          'M83.349 506.999c0 234.238 189.888 424.126 424.127 424.126s424.127-189.888 424.127-424.127c0-234.239-189.888-424.127-424.127-424.127-0.251 0-0.502 0-0.753 0.001l0.039-0c-233.931 0.407-423.412 190.138-423.412 424.126 0 0 0 0.001 0 0.001l-0-0z',
          'M530.099 300.056h-64.536v257.667l226.233 135.263 32.149-52.867-193.846-114.545v-225.518z',
          'M0 1024h1024v-1024h-1024v1024z',
          'M346.255 812.056h496.759v-631.070h-496.759v631.070z',
        ],
        attrs: [
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(254, 254, 254)',
          },
          {
            fill: 'rgb(254, 254, 254)',
          },
          {
            fill: 'rgb(254, 254, 254)',
          },
        ],
        isMulticolor: true,
        isMulticolor2: false,
        grid: 0,
        tags: ['hora'],
        colorPermutations: {
          '25425425419494941': [
            {
              f: 0,
            },
            {
              f: 1,
            },
            {
              f: 1,
            },
            {
              f: 1,
            },
          ],
        },
      },
      attrs: [
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(254, 254, 254)',
        },
        {
          fill: 'rgb(254, 254, 254)',
        },
        {
          fill: 'rgb(254, 254, 254)',
        },
      ],
      properties: {
        order: 1559,
        id: 176,
        name: 'hora',
        prevSize: 32,
        code: 59662,
        codes: [59662, 59663, 59664, 59665],
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 4,
    },
    {
      icon: {
        paths: [
          'M956.46 403.548h-2.905l-2.421 1.695-1.937 2.905-51.805 96.832c-1.208 2.176-2.507 4.882-3.664 7.663l-0.209 0.567c-1.053 2.184-2.198 4.985-3.201 7.853l-0.189 0.62-3.389-7.989c0-2.663-2.421-5.326-3.631-7.989l-52.289-96.832-2.179-2.905-2.421-1.695h-31.228v175.024h28.565v-114.020c0-2.179 0-4.6 0-7.020s0-5.084 0-7.747l53.5 99.495c1.113 2.219 2.764 4.026 4.787 5.294l0.055 0.032c1.879 1.215 4.175 1.937 6.64 1.937 0.049 0 0.097-0 0.146-0.001l-0.007 0h4.357c0.041 0 0.090 0.001 0.139 0.001 2.465 0 4.761-0.722 6.688-1.967l-0.049 0.029c2.078-1.3 3.729-3.106 4.807-5.251l0.034-0.075 52.774-99.253c0 2.663 0 5.084 0 7.747-0.056 1.012-0.087 2.197-0.087 3.389s0.032 2.377 0.094 3.553l-0.007-0.164v113.052h28.808v-175.024h-29.776z',
          'M1071.932 403.306l-68.751 175.024h24.208c0.072 0.002 0.157 0.003 0.243 0.003 2.544 0 4.897-0.818 6.811-2.205l-0.034 0.023c1.635-1.367 2.945-3.065 3.837-4.995l0.037-0.089 13.072-35.828h72.624l13.072 35.828c0.811 2.077 2.145 3.8 3.843 5.062l0.030 0.022c1.827 1.365 4.131 2.186 6.626 2.186 0.139 0 0.277-0.003 0.414-0.008l-0.020 0.001h24.208l-67.54-175.024zM1060.554 512.242l21.303-57.857c0-2.663 2.179-5.81 3.147-9.441s2.179-7.262 3.389-11.378c1.21 4.115 2.179 7.747 3.389 11.378l3.389 9.199 21.303 58.099z',
          'M1279.153 487.066l56.405-83.76h-31.228c-3.040 0.059-5.631 1.917-6.76 4.551l-0.019 0.049-36.554 57.373c-0.603 0.9-1.17 1.934-1.644 3.018l-0.050 0.129c-0.065 0.473-0.103 1.019-0.103 1.574s0.037 1.101 0.11 1.636l-0.007-0.062-39.943-64.393c-0.569-1.241-1.488-2.239-2.632-2.889l-0.031-0.016c-0.725-0.224-1.558-0.353-2.421-0.353s-1.696 0.129-2.481 0.369l0.060-0.016h-34.617l55.678 84.97-58.099 90.054h31.47c0.078 0.003 0.17 0.005 0.262 0.005 1.754 0 3.358-0.643 4.589-1.707l-0.009 0.008c1.152-1.152 2.189-2.419 3.091-3.783l0.056-0.090 38.975-61.973c-0.021-0.362-0.033-0.785-0.033-1.21s0.012-0.849 0.035-1.269l-0.003 0.058s0-1.695 0-2.663l40.67 67.056c0.804 1.528 1.859 2.816 3.126 3.856l0.021 0.017c1.316 1.065 3.009 1.709 4.854 1.709 0.166 0 0.331-0.005 0.495-0.016l-0.022 0.001h32.681z',
          'M176.477 184.223c0.507 0.009 1.104 0.014 1.703 0.014 27.301 0 52.197-10.294 71.019-27.213l-0.098 0.086c18.694-15.607 30.502-38.919 30.502-64.99 0-0.046-0-0.092-0-0.137l0 0.007c0.006-0.394 0.010-0.858 0.010-1.323 0-25.151-10.804-47.778-28.024-63.494l-0.067-0.060c-18.36-16.833-42.935-27.148-69.919-27.148-0.951 0-1.899 0.013-2.845 0.038l0.139-0.003c-1.024-0.037-2.228-0.058-3.437-0.058-26.755 0-51.096 10.334-69.25 27.228l0.062-0.057c-17.852 15.781-29.052 38.736-29.052 64.306 0 0.201 0.001 0.402 0.002 0.603l-0-0.031c-0.039 0.957-0.062 2.079-0.062 3.207 0 25.292 11.243 47.959 29.003 63.274l0.108 0.091c18.132 15.947 42.068 25.678 68.277 25.678 0.677 0 1.353-0.007 2.028-0.019l-0.101 0.002z',
          'M461.647 38.007v35.102h143.070v147.185h0.242v727.209h-143.312v35.102h178.171v-944.598h-178.171z',
          'M245.47 216.178h-133.386c-0.562-0.010-1.224-0.016-1.887-0.016-29.435 0-56.248 11.225-76.392 29.628l0.088-0.079c-20.205 17.247-32.934 42.74-32.934 71.205 0 0.499 0.004 0.997 0.012 1.494l-0.001-0.075v235.302c-0.007 0.397-0.010 0.866-0.010 1.336 0 20.532 7.12 39.401 19.026 54.273l-0.133-0.172c12.542 16.019 28.859 28.525 47.64 36.271l0.776 0.283v295.58c-0.014 0.547-0.022 1.192-0.022 1.838 0 22.573 9.903 42.833 25.6 56.674l0.082 0.071c15.872 15 37.343 24.221 60.968 24.221 0.524 0 1.046-0.005 1.568-0.014l-0.079 0.001h45.027c0.216 0.002 0.472 0.003 0.727 0.003 23.711 0 45.27-9.213 61.293-24.255l-0.048 0.044c16.628-14.080 27.113-34.97 27.113-58.309 0-0.011-0-0.023-0-0.034l0 0.002v-296.064c19.011-8.186 34.83-20.665 46.759-36.275l0.205-0.279c11.913-15.118 19.112-34.435 19.124-55.434l0-0.003v-235.302c-0.208-28.486-12.871-53.973-32.807-71.315l-0.116-0.099c-20.156-18.898-47.345-30.506-77.246-30.506-0.333 0-0.665 0.001-0.997 0.004l0.051-0z',
        ],
        attrs: [
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
        ],
        width: 1337,
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: ['max'],
        colorPermutations: {
          '25425425419494941': [
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
          ],
        },
      },
      attrs: [
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
      ],
      properties: {
        order: 1560,
        id: 175,
        name: 'max_height',
        prevSize: 32,
        code: 59666,
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 5,
    },
    {
      icon: {
        paths: [
          'M938.304 403.548h-2.905c-0.858 0.427-1.582 0.996-2.171 1.685l-0.008 0.009c-0.817 0.85-1.538 1.803-2.14 2.834l-0.038 0.071-51.805 96.832c-1.21 2.663-2.663 5.326-3.873 8.231s-2.179 5.568-3.389 8.473c0-2.905-2.421-5.568-3.389-8.473s-2.421-5.326-3.873-7.989l-52.289-96.832c-0.64-1.102-1.362-2.055-2.184-2.91l0.005 0.005c-0.596-0.698-1.321-1.268-2.139-1.677l-0.039-0.018h-30.502v175.024h28.565v-128.787l53.5 99.495c1.113 2.219 2.764 4.026 4.787 5.294l0.055 0.032c1.879 1.215 4.175 1.937 6.64 1.937 0.049 0 0.097-0 0.146-0.001l-0.007 0h3.147c0.041 0 0.090 0.001 0.139 0.001 2.465 0 4.761-0.722 6.688-1.967l-0.049 0.029c2.078-1.3 3.729-3.106 4.807-5.251l0.034-0.075 52.774-99.253c0 2.663 0 5.084 0 7.747s0 4.6 0 6.778v113.052h28.808v-174.54h-29.292z',
          'M1005.844 403.306h32.681v175.024h-32.681v-175.024z',
          'M1196.845 403.306v106.515c0 2.179 0 4.842 0 7.504v8.231l-91.991-116.925c-0.712-1.1-1.511-2.053-2.412-2.897l-0.008-0.008c-0.593-0.629-1.322-1.124-2.139-1.439l-0.040-0.013c-0.399-0.118-0.857-0.187-1.331-0.187s-0.933 0.068-1.366 0.195l0.034-0.009h-20.577v175.024h28.565v-123.219l91.506 116.441c1.308 1.845 3.017 3.321 5.006 4.322l0.078 0.036c1.818 0.766 3.932 1.211 6.15 1.211 0.051 0 0.102-0 0.152-0.001l-0.008 0h16.946v-174.782z',
          'M177.929 184.223c0.615 0.013 1.34 0.020 2.067 0.020 27.185 0 51.965-10.301 70.647-27.213l-0.090 0.080c18.694-15.607 30.502-38.919 30.502-64.99 0-0.046-0-0.092-0-0.137l0 0.007c-0.079-25.555-11.362-48.458-29.193-64.067l-0.099-0.085c-18.293-16.834-42.81-27.153-69.736-27.153-1.015 0-2.027 0.015-3.036 0.044l0.148-0.003c-1.024-0.037-2.228-0.058-3.437-0.058-26.755 0-51.096 10.334-69.25 27.228l0.062-0.057c-17.852 15.781-29.052 38.736-29.052 64.306 0 0.201 0.001 0.402 0.002 0.603l-0-0.031c-0.039 0.957-0.062 2.079-0.062 3.207 0 25.292 11.243 47.959 29.003 63.274l0.108 0.091c18.14 15.538 41.889 24.995 67.847 24.995 1.254 0 2.504-0.022 3.747-0.066l-0.18 0.005z',
          'M461.889 38.007v35.102h143.312v874.394h-143.312v35.102h178.171v-944.598h-178.171z',
          'M244.985 216.178h-133.386c-0.714-0.016-1.556-0.026-2.401-0.026-29.35 0-56.077 11.233-76.115 29.634l0.082-0.074c-20.347 17.295-33.172 42.91-33.172 71.519 0 0.388 0.002 0.776 0.007 1.164l-0.001-0.059v235.302c-0.002 0.212-0.003 0.464-0.003 0.715 0 20.692 7.211 39.699 19.258 54.647l-0.13-0.167c12.416 16.157 28.773 28.701 47.657 36.285l0.759 0.269v295.822c-0 0.039-0 0.086-0 0.132 0 23.286 10.275 44.173 26.538 58.373l0.091 0.078c15.941 15 37.472 24.218 61.156 24.218 0.457 0 0.914-0.003 1.369-0.010l-0.069 0.001h45.027c0.231 0.002 0.504 0.003 0.778 0.003 23.772 0 45.393-9.211 61.488-24.259l-0.051 0.047c15.781-14.076 25.669-34.468 25.669-57.17 0-0.412-0.003-0.823-0.010-1.234l0.001 0.062v-296.064c19.557-8.029 35.874-20.536 48.224-36.299l0.192-0.255c11.773-14.873 18.886-33.905 18.886-54.597 0-0.295-0.001-0.59-0.004-0.884l0 0.045v-235.060c0.003-0.29 0.005-0.634 0.005-0.977 0-28.6-12.717-54.23-32.805-71.544l-0.123-0.103c-19.824-18.335-46.438-29.581-75.679-29.581-1.139 0-2.274 0.017-3.405 0.051l0.166-0.004z',
        ],
        attrs: [
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
        ],
        width: 1226,
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: ['min'],
        colorPermutations: {
          '25425425419494941': [
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
          ],
        },
      },
      attrs: [
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
      ],
      properties: {
        order: 1561,
        id: 174,
        name: 'min_height',
        prevSize: 32,
        code: 59667,
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 6,
    },
    {
      icon: {
        paths: [
          'M51.2 192c0-56.32 46.080-102.4 102.4-102.4h716.8c56.554 0 102.4 45.846 102.4 102.4v0 716.8c0 56.554-45.846 102.4-102.4 102.4v0h-716.8c-56.554 0-102.4-45.846-102.4-102.4v0-716.8zM153.6 294.4v614.4h716.8v-614.4h-716.8zM256-12.8h102.4v102.4h-102.4v-102.4zM665.6-12.8h102.4v102.4h-102.4v-102.4zM256 448h102.4v102.4h-102.4v-102.4zM256 652.8h102.4v102.4h-102.4v-102.4zM460.8 448h102.4v102.4h-102.4v-102.4zM460.8 652.8h102.4v102.4h-102.4v-102.4zM665.6 448h102.4v102.4h-102.4v-102.4zM665.6 652.8h102.4v102.4h-102.4v-102.4z',
        ],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ['calendar'],
        defaultCode: 59648,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 33,
        order: 1060,
        ligatures: '',
        prevSize: 32,
        code: 59711,
        name: 'calendario',
      },
      setIdx: 5,
      setId: 2,
      iconIdx: 32,
    },
    {
      icon: {
        paths: [
          'M682.667 880.86c-143.14 0-267.011-79.828-327.57-198.194h327.57v-112.86h-366.108c-2.753-19.269-5.505-38.538-5.505-57.806s2.753-38.538 5.505-57.806h366.108v-112.86h-327.57c60.559-118.366 184.43-198.194 327.57-198.194 90.839 0 176.172 33.032 239.484 88.086l101.849-101.849c-90.839-79.828-211.957-129.376-341.333-129.376-222.968 0-412.903 143.14-481.72 341.333h-200.946v112.86h173.419c-2.753 19.269-2.753 38.538-2.753 57.806s0 38.538 2.753 57.806h-173.419v112.86h200.946c71.57 198.194 258.753 341.333 481.72 341.333 132.129 0 250.495-49.548 341.333-132.129l-101.849-101.849c-63.312 57.806-148.645 90.839-239.484 90.839z',
        ],
        attrs: [
          {
            fill: 'rgb(94, 94, 94)',
          },
        ],
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: ['pago'],
      },
      attrs: [
        {
          fill: 'rgb(94, 94, 94)',
        },
      ],
      properties: {
        order: 3,
        id: 0,
        name: 'pago',
        prevSize: 32,
        code: 59649,
      },
      setIdx: 0,
      setId: 2,
      iconIdx: 1,
    },
    {
      icon: {
        paths: [
          'M366.108 355.097c2.753 2.753 8.258 2.753 11.011 2.753v0c11.011 0 19.269-8.258 19.269-19.269 0-2.753 0-2.753 0-5.505-11.011-52.301-5.505-93.591 16.516-123.871 27.527-35.785 38.538-79.828 30.28-121.118-5.505-35.785-24.774-66.065-55.054-85.333-5.505-5.505-16.516-5.505-22.022 0s-8.258 13.763-8.258 22.022c13.763 55.054 0 88.086-27.527 140.387-16.516 33.032-24.774 68.817-19.269 101.849 5.505 35.785 24.774 66.065 55.054 88.086z',
          'M531.269 344.086c2.753 2.753 5.505 2.753 11.011 2.753v0c11.011 0 19.269-8.258 19.269-19.269 0-2.753 0-2.753 0-5.505-8.258-38.538-5.505-71.57 11.011-93.591 22.022-27.527 30.28-63.312 24.774-96.344-5.505-27.527-19.269-52.301-44.043-68.817-5.505-2.753-13.763-5.505-19.269 0s-8.258 11.011-8.258 19.269c8.258 41.29 0 66.065-22.022 107.355-13.763 24.774-19.269 55.054-16.516 79.828 2.753 33.032 19.269 57.806 44.043 74.323z',
          'M1048.774 470.71c-11.011-13.763-30.28-22.022-49.548-22.022h-90.839c2.753-16.516 0-38.538-13.763-52.301-8.258-11.011-19.269-13.763-33.032-13.763h-809.29c-22.022 0-33.032 8.258-38.538 16.516-13.763 13.763-16.516 38.538-13.763 55.054 22.022 137.634 71.57 261.505 134.882 349.591 38.538 52.301 82.581 93.591 132.129 123.871-8.258 11.011-11.011 22.022-11.011 35.785 0 35.785 27.527 63.312 63.312 63.312h264.258c35.785 0 63.312-27.527 63.312-63.312 0-13.763-5.505-24.774-11.011-35.785 41.29-22.022 77.075-52.301 110.108-90.839 156.903-13.763 294.538-165.161 316.559-300.043 5.505-13.763 8.258-44.043-13.763-66.065zM825.806 712.946c27.527-57.806 49.548-118.366 63.312-173.419h79.828c-19.269 66.065-74.323 134.882-143.14 173.419z',
        ],
        attrs: [
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
        ],
        width: 1065,
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: ['consumicion'],
      },
      attrs: [
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
      ],
      properties: {
        order: 2,
        id: 1,
        name: 'consumicion',
        prevSize: 32,
        code: 59648,
      },
      setIdx: 0,
      setId: 2,
      iconIdx: 0,
    },
    {
      icon: {
        paths: [
          'M506.371 636.329c-0.073 0-0.158 0-0.244 0-34.197 0-61.92-27.722-61.92-61.92s27.722-61.92 61.92-61.92c34.197 0 61.92 27.722 61.92 61.92 0 0.086-0 0.172-0.001 0.257l0-0.013c0 34.062-27.613 61.675-61.675 61.675v0zM351.449 841.912c-69.911-0.139-126.532-56.845-126.532-126.776 0-0 0-0 0-0l0-0h253.797c0 0 0 0.001 0 0.001 0 70.017-56.76 126.776-126.776 126.776-0.172 0-0.344-0-0.516-0.001l0.027 0zM136.076 574.654c-0-0.073-0-0.158-0-0.244 0-34.197 27.722-61.92 61.92-61.92s61.92 27.722 61.92 61.92c0 34.197-27.722 61.92-61.92 61.92-0.086 0-0.172-0-0.257-0.001l0.013 0c-34.062 0-61.675-27.613-61.675-61.675v-0zM704.122 335.541v-54.088l-54.088 12.971c-82.166 17.275-176.581 27.165-273.305 27.165-9.147 0-18.274-0.088-27.379-0.265l1.365 0.021c-7.707 0.154-16.794 0.242-25.9 0.242-96.679 0-191.047-9.89-282.149-28.714l8.975 1.55-52.375-12.971v391.587c0 119.189 63.633 350.96 351.449 350.96s351.694-231.771 351.694-350.96z',
          'M956.451 335.296c-23.249 0-42.096-18.847-42.096-42.096s18.847-42.096 42.096-42.096c23.249 0 42.096 18.847 42.096 42.096v0c-0.138 23.193-18.903 41.958-42.082 42.096l-0.013 0zM1152.245 0.245l-52.375 12.971c-82.090 17.107-176.426 26.901-273.049 26.901-8.807 0-17.595-0.081-26.363-0.243l1.316 0.019c-7.553 0.147-16.46 0.23-25.386 0.23-96.676 0-191.064-9.796-282.225-28.45l9.026 1.543-52.375-13.216v234.218c65.756-2.702 127.892-10.504 188.281-23.073l-7.417 1.291 51.885-12.971 104.505-25.698v218.799c2.12-0.12 4.601-0.189 7.098-0.189s4.977 0.068 7.44 0.204l-0.343-0.015c69.911 0.139 126.532 56.845 126.532 126.776 0 0-0 0-0 0l-0 0h-141.216v227.12c71.505-10.006 135.831-33.931 192.525-69.012l-2.116 1.219c114.784-68.772 175.235-166.669 175.235-287.082v-391.587z',
          'M1017.147 293.201c0 0.073 0 0.158 0 0.244 0 34.197-27.722 61.92-61.92 61.92s-61.92-27.722-61.92-61.92c0-34.197 27.722-61.92 61.92-61.92 0.086 0 0.172 0 0.257 0.001l-0.013-0c34.062 0 61.675 27.613 61.675 61.675v0z',
        ],
        attrs: [
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(94, 94, 94)',
          },
          {
            fill: 'rgb(254, 254, 254)',
          },
        ],
        width: 1153,
        isMulticolor: true,
        isMulticolor2: false,
        grid: 0,
        tags: ['espect'],
        colorPermutations: {
          '25425425419494941': [
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 1,
            },
          ],
        },
      },
      attrs: [
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(94, 94, 94)',
        },
        {
          fill: 'rgb(254, 254, 254)',
        },
      ],
      properties: {
        order: 1557,
        id: 178,
        name: 'espectaculo',
        prevSize: 32,
        code: 59658,
        codes: [59658, 59659, 59660],
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 2,
    },
    {
      icon: {
        paths: [
          'M810 295.333l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z',
        ],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ['clear'],
        defaultCode: 57456,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 2,
        order: 1563,
        ligatures: '',
        prevSize: 32,
        code: 57456,
        name: 'clear',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 11,
    },
  ],
};

export default magniIcons;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styles from './headerBlock.module.scss';
import textSize from '../../../helpers/textSize';
import PawLink from '../../PawLink/PawLink';
import tt from '../../../helpers/translation';

export const HeaderData = graphql`
  fragment HeaderData on headerBlock {
    title
    titleTag
    titleStyle
    body
    urlText
    url
    blockid
    pageid
    id
    name
    readMore
    readMoreText
    readLessCopy
    readMoreCopy
    position
    insideBox
  }
`;

/**
 * It is used as header of blocks. All props should be inside data prop.
 * @property {String} title //texto
 * @property {String} titleTag //H1, H2, H3, H4, H5, H6
 * @property body - Map, Stars, Location
 */

const HeaderBlock = (data) => {
  const {
    title,
    titleTag,
    titleStyle,
    body,
    urlText,
    url,
    readMore,
    readMoreText,
    readLessCopy,
    readMoreCopy,
    insideBox,
    pageid,
  } = data;
  const [showBox, setshowBox] = useState(false);
  const toggleBox = () => {
    setshowBox(!showBox);
  };
  let headerBlockBody;

  if (body || urlText) {
    headerBlockBody = (
      <>
        {body ? (
          <div className={styles.headerSubtitle} dangerouslySetInnerHTML={{ __html: body }} />
        ) : null}
        {readMore ? (
          <div className={styles.seemore} onClick={toggleBox}>
            {!showBox ? <p>{readMoreCopy}</p> : <p>{readLessCopy}</p>}
          </div>
        ) : null}
        <div
          className={showBox ? styles.opened : styles.closed}
          dangerouslySetInnerHTML={{ __html: readMoreText }}
        />
        {/* {urlText && ![731, 732, 733, 734].includes(pageid) ? (
          <div className={styles.headerLink}>
            <PawLink destination={url} type="named">
              {urlText}
            </PawLink>
          </div>
        ) : null} */}

        {/* {[731, 732, 733, 734].includes(pageid) && (
          <h3
            style={{
              marginTop: '20px',
              marginBottom: '-20px',
              fontWeight: 'bold',
              fontSize: '22px',
              color: '#5e5e5e',
            }}
          >
            {urlText}
          </h3>
        )} */}
      </>
    );
  }

  return (
    <div className={`${styles.headerBlockContainer} ${insideBox ? styles.insideBox : null}`}>
      {textSize(title, titleTag, titleStyle, pageid)}
      {headerBlockBody}
    </div>
  );
};

export default HeaderBlock;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Buttons.module.scss';
import PawLink from '../../PawLink/PawLink';

/**
 * Return a Button.
 * @property {boolean} noRel - The element.
 * @property {boolean} blank - The element.
 * @property {function} onClick - The element.
 * @property {string} link - The element.
 * @property {string} text - The element.
 * @property {string} color - options (white | orange | Blancotransparente | Blanco | Naranja | SALE ).
 * @property {string} size - options (small | alone ).
 * @property {string} exception - options (margin_top  | mini_button | mapa | special | header | disabled).
 * @return {Component} componente Button
 */

const Button = ({
  noRel,
  link,
  size,
  color,
  text,
  blank,
  className,
  exception,
  onClick,
  named,
}) => {
  let classButton;
  let sizeClass;
  let colorClass;
  let exceptClass;

  if (
    color ===
    ![
      'white',
      'orange',
      'blanco_hover',
      'naranja_hover',
      'blanco_transparente',
      'blanco',
      'naranja_transparente',
      'naranja',
      'sale',
    ].includes(color)
  ) {
    console.log('No se ha introducido ningún valor de color correcto');
    console.log(color, size, exception);
  }
  if (size === !['alone', 'small'].includes(size)) {
    console.log('No se ha introducido ningún valor de tamaño correcto');
    console.log(color, size, exception);
  }

  if (size === 'alone') {
    sizeClass = 'alone';
  } else {
    sizeClass = 'small';
  }

  if (color === 'sale') {
    colorClass = 'oferta';
  } else {
    colorClass = color;
  }

  if (exception) {
    exceptClass = exception;
  }
  if (className) {
    classButton = className;
  }

  let isNorel;
  let isOnClick;
  if (onClick) {
    isOnClick = (
      // <div className={`button ${classButton}`} onClick={onClick}>
      <button
        type="button"
        onClick={onClick}
        className={`${styles.button} ${styles[colorClass]} ${styles[sizeClass]} 
        ${exceptClass && styles[exceptClass]} ${classButton && classButton} `}
      >
        {text}
      </button>
    );
  } else if (named) {
    isOnClick = (
      <PawLink
        type="named"
        destination="plan_your_stay"
        parameters={['']}
        className={`${styles.button} ${styles[colorClass]} ${styles[sizeClass]} 
        ${exceptClass && styles[exceptClass]} ${classButton && classButton} `}
      >
        {text}
      </PawLink>
    );
  }
  if (noRel) {
    isNorel = (
      <PawLink
        type="unknown"
        destination={link}
        rel={noRel}
        className={`${styles.button} ${styles[colorClass]} ${styles[sizeClass]} 
        ${exceptClass && styles[exceptClass]} ${classButton && classButton} `}
      >
        {text}
      </PawLink>
    );
  } else if (blank) {
    isNorel = (
      <PawLink
        type="anchor"
        destination={link}
        rel={noRel}
        className={`${styles.button} ${styles[colorClass]} ${styles[sizeClass]} 
        ${exceptClass && styles[exceptClass]} ${classButton && classButton} `}
      >
        {text}
      </PawLink>
    );
  } else {
    isNorel = (
      <PawLink
        type="unknown"
        destination={link}
        className={`${styles.button} ${styles[colorClass]} ${styles[sizeClass]} 
        ${exceptClass && styles[exceptClass]} ${classButton && classButton} `}
      >
        {text}
      </PawLink>
    );
  }

  return <>{onClick || named ? isOnClick : isNorel}</>;
};

Button.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.string,
  size: PropTypes.string,
  noRel: PropTypes.string,
  blank: PropTypes.boolean,
  exception: PropTypes.string,
  onClick: PropTypes.function,
  named: PropTypes.boolean,
};

export default Button;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { graphql } from 'gatsby';
import React from 'react';
import Slider from 'react-slick';
import SliderSettings from '../SliderSettings/SliderSettings';
import Card from './cards';
import styles from './ticketCard.module.scss';

export const TicketCardsData = graphql`
  fragment TicketCardsData on TicketCardsBlock {
    id
    blockid
    pageid
    specialInfo
    position
    subBlock {
      title
      subtitle
      firstText
      secondText
      firstTextInfo
      secondTextInfo
      firstHeader
      secondHeader
      firstPriceTaquilla
      firstPriceWeb
      firstExtraPrice
      secondPriceTaquilla
      secondPriceWeb
      secondExtraPrice
      cta
      ctaText
      ctaSize
      ctaStyle
      firstExtraLink
      secondExtraLink
      popUpText
      calendarStyle
      firstPopUpTitle
      secondPopUpTitle
      secondPopUpText
      isDisabled
      cardColor
      position
      handicappedPrice
    }
  }
`;

const TicketCards = ({ subBlock }) => {
  const subBlockFilter = subBlock.filter((el) => !el.isDisabled);

  return (
    <div className={styles.ticketCardsContainer}>
      {subBlockFilter ? (
        <>
          <div className={`${styles.cardsComponent} hide-md`}>
            {subBlockFilter.map((card) => (
              <Card {...card} />
            ))}
          </div>
          <div className={`${styles.cardsComponent} hide-md-xl`}>
            <Slider {...SliderSettings('normalNoArrows')}>
              {subBlockFilter.map((card) => (
                <Card {...card} />
              ))}
            </Slider>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TicketCards;

import React from 'react';
import { graphql } from 'gatsby';
import styles from './discoverElements.module.scss';

import DiscoverCards from './DiscoverCard/DiscoverCard';

export const discoverBlock = graphql`
  fragment discoverElementsBlock on DiscoverElementsBlock {
    id
    blockid
    pageid
    position
    DiscoverElementsSubBlock {
      title
      titleStyle
      titleTag
      description
      title_url
      position
      altImage
      altLogo
      buttons {
        button1 {
          link
          text
          color
          size
          noRel
          exception
          blank
        }
        button2 {
          link
          text
          color
          size
          noRel
          exception
          blank
        }
      }
      localImage1 {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      localImage2 {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

const DiscoverElements = ({ DiscoverElementsSubBlock }) => {
  return (
    <div className={styles.DiscoverContainer}>
      {DiscoverElementsSubBlock.sort((a, b) => a.position - b.position).map((card) => (
        <DiscoverCards {...card} key={card.title} />
      ))}
    </div>
  );
};

export default DiscoverElements;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import Icon from '../../../helpers/icon';
import Buttons from '../Buttons/Buttons';
import ticketsIcons from './icons';
import styles from './ticketCard.module.scss';

const Cards = ({
  title,
  subtitle,
  firstText,
  secondText,
  firstTextInfo,
  secondTextInfo,
  firstHeader,
  secondHeader,
  firstPriceTaquilla,
  firstPriceWeb,
  firstExtraPrice,
  secondPriceTaquilla,
  secondPriceWeb,
  secondExtraPrice,
  handicappedPrice,
  cta,
  ctaText,
  ctaSize,
  ctaStyle,
  firstExtraLink,
  secondExtraLink,
  popUpText,
  firstPopUpTitle,
  secondPopUpTitle,
  secondPopUpText,
  isDisabled,
  cardColor,
}) => {
  const [showPopup, setshowPopup] = useState(false);
  const [firstPop, setfirstPop] = useState(false);
  const [secondPop, setsecondPop] = useState(false);
  const [calendarName, calendarLink] = secondExtraLink.split('|');
  const handlePopups = () => {
    setshowPopup(!showPopup);
  };

  const handleFirstPop = () => {
    setsecondPop(false);
    setfirstPop(!firstPop);
  };
  const handleSecondPop = () => {
    setfirstPop(false);
    setsecondPop(!secondPop);
  };

  if (isDisabled) {
    return null;
  }

  return (
    <div className={styles.cardContainer}>
      {showPopup ? (
        <div className={styles.cardContent}>
          <div className={styles.closeCross} onClick={handlePopups}>
            X
          </div>
          <div className={styles.popupTitle} onClick={handleFirstPop}>
            {firstPopUpTitle}
          </div>
          <div
            className={firstPop ? styles.opened : styles.closed}
            dangerouslySetInnerHTML={{ __html: popUpText }}
          />
          <div className={styles.popupTitle} onClick={handleSecondPop}>
            {secondPopUpTitle}
          </div>
          <div
            className={secondPop ? styles.opened : styles.closed}
            dangerouslySetInnerHTML={{ __html: secondPopUpText }}
          />
        </div>
      ) : (
        <div className={styles.cardContent}>
          <div
            className={styles.cardHeader}
            style={{
              backgroundColor: `${cardColor || '#fff'}`,
            }}
          >
            <p className={styles.headerTitle}>{title}</p>
            <p>{subtitle}</p>
          </div>

          <div className={styles.cardBody}>
            <div className={styles.row}>
              <div className={styles.col} />
              <div className={styles.col}>{firstHeader}</div>
              <div className={styles.col}>{secondHeader}</div>
            </div>
            <div className={`${styles.row} ${styles.underline}`}>
              <div className={`${styles.col} ${styles.info}`}>
                <div className={styles.rowText}>
                  <p>{firstText}</p>
                  <Icon
                    icon="info-icon"
                    width="13"
                    height="13"
                    color={cardColor}
                    class="is-centered"
                    iconSet={ticketsIcons}
                  />
                </div>
                <span className={styles.infoText}>{firstTextInfo}</span>
              </div>
              <div className={styles.col}>
                <p
                  className={styles.specialPrice}
                  style={{
                    color: `${cardColor || '#5e5e5e'}`,
                  }}
                >
                  <a href={cta} className="t-link-precio-entrada">
                    {firstPriceWeb}€
                  </a>
                </p>
                {firstExtraPrice ? (
                  <span className={styles.secondExtraPrice}>{`${firstExtraPrice}€`}</span>
                ) : null}
              </div>
              <div className={styles.col}>{firstPriceTaquilla}€</div>
            </div>
            <div className={styles.row}>
              <div className={`${styles.col} ${styles.info}`}>
                <div className={styles.rowText}>
                  <p>{secondText}</p>
                  <Icon
                    icon="info-icon"
                    width="13"
                    height="13"
                    color={cardColor}
                    class="is-centered"
                    iconSet={ticketsIcons}
                  />
                </div>
                <span className={styles.infoText}>{secondTextInfo}</span>
              </div>
              <div className={styles.col}>
                <p
                  className={styles.specialPrice}
                  style={{
                    color: `${cardColor || '#5e5e5e'}`,
                  }}
                >
                  <a href={cta} className="t-link-precio-entrada">
                    {secondPriceWeb}€
                  </a>
                </p>
                {secondExtraPrice ? (
                  <span className={styles.secondExtraPrice}>{`${secondExtraPrice}€`}</span>
                ) : null}
              </div>
              <div className={styles.col}>{secondPriceTaquilla}€</div>
            </div>
            {handicappedPrice ? (
              <p
                className={styles.extraPrice}
                style={{
                  color: '#5e5e5e',
                }}
              >
                <Icon
                  icon="ReducedMobility"
                  width="13"
                  height="13"
                  color="#5e5e5e"
                  class="is-centered"
                  iconSet={ticketsIcons}
                />
                <a href={cta} className="t-link-precio-entrada">
                  {handicappedPrice}€
                </a>
              </p>
            ) : null}
            <div className={styles.cardBodyFooter}>
              {cta ? (
                <Buttons
                  noRel="nofollow"
                  link={ctaText === 'НЕТ В НАЛИЧИИ' ? '#' : cta}
                  color={ctaText === 'НЕТ В НАЛИЧИИ' ? 'desactivado' : 'naranja'}
                  size={ctaSize}
                  text={ctaText}
                  blank
                />
              ) : null}
              <div className={styles.row}>
                <div className={`${styles.col} ${styles.half}`} onClick={handlePopups}>
                  <p>{firstExtraLink}</p>
                </div>
                <a
                  className={`${styles.col} ${styles.half}`}
                  target="_blank"
                  href={calendarLink}
                  rel="noreferrer"
                >
                  <p>{calendarName}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cards;

import React from 'react';
import Pawlink from '../../PawLink/PawLink';
import styles from './socialNetwork.module.scss';

const RRSS = () => {
  return (
    <div className={`${styles.images} t-bigimage-social`}>
      <Pawlink
        type="unknown"
        destination="https://wa.me/whatsappphonenumber/?text=urlencodedtext"
        rel=" noopener "
      >
        <div className={styles.imgWhatsapp} />
      </Pawlink>
      <Pawlink type="unknown" destination="https://twitter.com/Portaventura_ES" rel=" noopener ">
        <div className={styles.imgTwitter} />
      </Pawlink>
      <Pawlink
        type="unknown"
        destination="https://www.facebook.com/portaventuraoficial"
        rel=" noopener "
      >
        <div className={styles.imgFacebook} />
      </Pawlink>
      <Pawlink
        type="unknown"
        destination="https://www.youtube.com/user/PortAventuraTV"
        rel=" noopener "
      >
        <div className={styles.imgYoutube} />
      </Pawlink>
      <Pawlink
        type="unknown"
        destination="https://www.instagram.com/portaventuraworld"
        rel=" noopener "
      >
        <div className={styles.imgInstagram} />
      </Pawlink>
    </div>
  );
};

export default RRSS;

import React from 'react';
import { graphql } from 'gatsby';
import botArrow from '../../images/bot-arrow.svg';
import style from './BlueArrow.module.scss';

export const BlueArrowData = graphql`
  fragment BlueArrowData on BlueArrowBlock {
    id
    blockid
    pageid
  }
`;
const BlueArrow = () => {
  return (
    <div className={style.botArrow}>
      <img src={botArrow} alt="arrow-blue" />
    </div>
  );
};

export default BlueArrow;

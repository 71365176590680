const tabsIcons = {
  IcoMoonType: 'selection',
  icons: [
    {
      icon: {
        paths: [
          'M205.335 442.671c83.734 162.884 213.119 292.268 371.217 373.76l4.785 2.242 125.326-125.342q26.68-26.68 58.673-13.34c59.492 20.299 128.028 32.017 199.301 32.017 1.184 0 2.366-0.003 3.548-0.010l-0.182 0.001c0.395-0.010 0.86-0.016 1.327-0.016 30.203 0 54.686 24.484 54.686 54.686 0 0.466-0.006 0.932-0.017 1.395l0.001-0.069v199.999c0.010 0.395 0.016 0.86 0.016 1.327 0 30.203-24.484 54.686-54.686 54.686-0.466 0-0.932-0.006-1.395-0.017l0.069 0.001q-399.998 0-683.993-284.002t-284.010-683.993c-0.010-0.395-0.016-0.86-0.016-1.327 0-30.203 24.484-54.686 54.686-54.686 0.469 0 0.937 0.006 1.403 0.018l-0.069-0.001h199.991c0.397-0.010 0.865-0.016 1.334-0.016 30.203 0 54.686 24.484 54.686 54.686 0 0.467-0.006 0.932-0.017 1.395l0.001-0.069c-0.006 0.992-0.009 2.165-0.009 3.339 0 71.281 11.712 139.826 33.317 203.817l-1.315-4.488c2.221 5.714 3.507 12.329 3.507 19.243 0 15.491-6.458 29.473-16.828 39.403l-0.020 0.019z',
        ],
        attrs: [
          {
            fill: 'rgb(94, 94, 94)',
          },
        ],
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: ['icono_telefono_150x150'],
      },
      attrs: [
        {
          fill: 'rgb(94, 94, 94)',
        },
      ],
      properties: {
        order: 1058,
        id: 0,
        name: 'icono_telefono_150x150',
        prevSize: 32,
        code: 59648,
      },
      setIdx: 0,
      setId: 7,
      iconIdx: 0,
    },
    {
      icon: {
        paths: [
          'M621.254 877.254l320-320c24.994-24.992 24.994-65.516 0-90.51l-320-320c-24.994-24.992-65.516-24.992-90.51 0-24.994 24.994-24.994 65.516 0 90.51l210.746 210.746h-613.49c-35.346 0-64 28.654-64 64s28.654 64 64 64h613.49l-210.746 210.746c-12.496 12.496-18.744 28.876-18.744 45.254s6.248 32.758 18.744 45.254c24.994 24.994 65.516 24.994 90.51 0z',
        ],
        tags: ['arrow-right', 'right', 'next'],
        defaultCode: 59964,
        grid: 16,
        attrs: [],
      },
      attrs: [],
      properties: {
        ligatures: 'arrow-right2, right4',
        name: 'arrow-right2',
        order: 4,
        id: 317,
        prevSize: 32,
        code: 59964,
      },
      setIdx: 1,
      setId: 1,
      iconIdx: 316,
    },
    {
      icon: {
        paths: [
          'M205.437 281.879l274.712 295.415 273.916-295.415 84.404 90.774-358.32 386.986-358.32-386.986z',
        ],
        attrs: [
          {
            fill: 'rgb(96, 96, 94)',
          },
        ],
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: ['flecha-mobile-gris_Mesa de trabajo 1'],
        colorPermutations: {
          '25525525519696941': [
            {
              f: 0,
            },
          ],
        },
      },
      attrs: [
        {
          fill: 'rgb(96, 96, 94)',
        },
      ],
      properties: {
        order: 4,
        id: 1,
        name: 'flecha-mobile-gris',
        prevSize: 32,
        code: 59649,
      },
      setIdx: 0,
      setId: 2,
      iconIdx: 1,
    },
  ],
};

export default tabsIcons;

const bragiIcons = {
  IcoMoonType: 'selection',
  icons: [
    {
      icon: {
        paths: [
          'M512 85.333c-235.52 0-426.667 191.147-426.667 426.667s191.147 426.667 426.667 426.667 426.667-191.147 426.667-426.667-191.147-426.667-426.667-426.667zM554.667 725.333h-85.333v-256h85.333v256zM554.667 384h-85.333v-85.333h85.333v85.333z',
        ],
        attrs: [{}],
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: ['info-24px (1)'],
      },
      attrs: [{}],
      properties: {
        order: 2,
        id: 0,
        name: 'info-icon',
        prevSize: 32,
      },
      setIdx: 0,
      setId: 2,
      iconIdx: 0,
    },
    {
      icon: {
        paths: [
          'M449.757 961.757c-114.447 0-208.816-94.369-208.816-208.816 0-76.298 40.157-140.549 100.392-178.698l-12.047-64.251c-88.345 44.173-148.58 136.533-148.58 242.949 0 150.588 120.471 271.059 271.059 271.059 148.58 0 271.059-122.478 271.059-271.059h-62.243c-2.008 114.447-96.376 208.816-210.824 208.816zM417.631 208.816c58.227 0 104.408-46.18 104.408-104.408s-46.18-104.408-104.408-104.408-104.408 46.18-104.408 104.408c2.008 58.227 48.188 104.408 104.408 104.408zM921.6 891.482l-52.204-261.020c-6.024-32.125-34.133-54.212-66.259-54.212h-210.824l-50.196-250.98c-12.047-56.22-66.259-92.361-122.478-82.322l-230.902 46.18c-22.086 4.016-40.157 22.086-44.173 44.173l-42.165 188.737c-6.024 30.118 12.047 62.243 44.173 68.267 4.016 0 8.031 2.008 12.047 2.008 26.102 0 50.196-18.071 56.22-44.173l34.133-150.588 92.361-18.071 50.196 250.98c10.039 50.196 54.212 84.329 102.4 84.329h257.004l42.165 206.808c6.024 32.125 34.133 54.212 66.259 54.212 4.016 0 8.031 0 14.055-2.008 32.125-10.039 56.22-46.18 48.188-82.322z',
        ],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ['ReducedMobility'],
        defaultCode: 59707,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 92,
        order: 1128,
        ligatures: '',
        prevSize: 32,
        code: 59712,
        name: 'ReducedMobility',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 91,
    },
    {
      icon: {
        paths: [
          'M449.757 961.757c-114.447 0-208.816-94.369-208.816-208.816 0-76.298 40.157-140.549 100.392-178.698l-12.047-64.251c-88.345 44.173-148.58 136.533-148.58 242.949 0 150.588 120.471 271.059 271.059 271.059 148.58 0 271.059-122.478 271.059-271.059h-62.243c-2.008 114.447-96.376 208.816-210.824 208.816zM417.631 208.816c58.227 0 104.408-46.18 104.408-104.408s-46.18-104.408-104.408-104.408-104.408 46.18-104.408 104.408c2.008 58.227 48.188 104.408 104.408 104.408zM921.6 891.482l-52.204-261.020c-6.024-32.125-34.133-54.212-66.259-54.212h-210.824l-50.196-250.98c-12.047-56.22-66.259-92.361-122.478-82.322l-230.902 46.18c-22.086 4.016-40.157 22.086-44.173 44.173l-42.165 188.737c-6.024 30.118 12.047 62.243 44.173 68.267 4.016 0 8.031 2.008 12.047 2.008 26.102 0 50.196-18.071 56.22-44.173l34.133-150.588 92.361-18.071 50.196 250.98c10.039 50.196 54.212 84.329 102.4 84.329h257.004l42.165 206.808c6.024 32.125 34.133 54.212 66.259 54.212 4.016 0 8.031 0 14.055-2.008 32.125-10.039 56.22-46.18 48.188-82.322z',
        ],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ['ReducedMobility'],
        defaultCode: 59707,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 92,
        order: 1128,
        ligatures: '',
        prevSize: 32,
        code: 59712,
        name: 'ReducedMobility',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 91,
    },
  ],
};

export default bragiIcons;

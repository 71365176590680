import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import styles from './map.module.scss';
import ModalMap from '../Widgets/ModalMap/ModalMap';
import Button from '../Buttons/Buttons';

export const MapData = graphql`
  fragment MapData on mapBlock {
    buttonText
    buttonColor
    blockid
    pageid
    image
    position
    localImage {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const MapContainer = props => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Map
        {...props}
        openMap={() => {
          setOpen(true);
        }}
      />
      {isOpen ? (
        <ModalMap onClose={() => setOpen(false)} isMap>
          <img src={props.data.image} className={styles.finalImage} />
        </ModalMap>
      ) : null}
    </>
  );
};

const Map = props => {
  return <ContentDesktop {...props} />;
};

const ContentDesktop = ({ data, openMap }) => {
  return (
    <div className={styles.mapContainer}>
      <div className={styles.mapButton}>
        <Button size="alone" color="blanco" text={data.buttonText} onClick={openMap} />
      </div>
      <Img fluid={data.localImage.childImageSharp.fluid} className={styles.dualTest} />
    </div>
  );
};

export default MapContainer;

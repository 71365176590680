import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import textSize from '../../../helpers/textSize';
import styles from './bigImageCard.module.scss';
import Buttons from '../Buttons/Buttons';
import RRSS from '../SocialNetwork/SocialNetwork';

export const BigImgCard = graphql`
  fragment BigImgCard on bigImageCardBlock {
    id
    tag
    pageid
    position
    blockid
    lang
    title
    titleTag
    titleStyle
    description
    hasRrss
    isTwoButton
    cardButtons {
      cta
      blank
      ctaText
      color
      size
      cta2
      blank2
      ctaText2
      color2
      size2
    }
    image
    altImage
    localImage {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
/**
 * It is a card with a big image on the left side.
 * @property {HTML} image - Left side image
 * @property {HTML} title - Card title
 * @property {String} titleTag - Tag of the title
 * @property {string} titleStyle - Style of the title
 * @property {HTML} description - Card description
 * @property {HTML} cardButtons - Card button
 * @property {Component} rrss - Add images with link of rrss
 */

const CreateButtons = ({ cta, cta2, size, color, color2, ctaText, ctaText2, blank }) => (
  <div className={styles.buttonContainer}>
    {cta && cta2 ? (
      <Buttons
        className="t-promo-secondary-button"
        link={cta}
        size={size}
        color={color}
        text={ctaText}
        blank={blank}
      />
    ) : null}
    {cta && !cta2 ? (
      <Buttons link={cta} size={size} color={color} text={ctaText} blank={blank} />
    ) : null}

    {cta2 ? <Buttons link={cta2} size={size} color={color2} text={ctaText2} blank={blank} /> : null}
  </div>
);

const BodyCard = ({ description, hasRrss, cardButtons }) => (
  <>
    {description ? (
      <div className={styles.boxText} dangerouslySetInnerHTML={{ __html: description }} />
    ) : null}
    <CreateButtons {...cardButtons} />
    {hasRrss ? <RRSS /> : null}
  </>
);

export const BigImageCard = (props) => {
  const { title, titleTag, titleStyle, localImage, altImage, cardButtons } = props;
  return (
    <div className={styles.bigImageWithCardContainer}>
      <div className={styles.imgContainer}>
        <a href={cardButtons.cta} className="t-wide-slider-image-link">
          {localImage ? (
            <Img
              className={styles.imgFullwidth}
              fluid={localImage.childImageSharp.fluid}
              alt={altImage}
            />
          ) : (
            <span />
          )}
        </a>
      </div>
      <div className={styles.boxContainer}>
        {textSize(title, titleTag, titleStyle)}
        <BodyCard {...props} />
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import Icon from '../../../helpers/icon';
import styles from './banner.module.scss';
import PawLink from '../../PawLink/PawLink';

export const BannerData = graphql`
  fragment BannerData on bannerBlock {
    image
    localImage {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageMobile
    localImage1 {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    position
  }
`;

export const Banner = ({ image, imageMobile, localImage, localImage1 }) => {
  return (
    <>
      <div className="hide-md">
        <div
          className={
            localImage.childImageSharp.fluid.sizes.includes('600')
              ? styles.bannerSmall
              : styles.bannerImage
          }
        >
          <Img fluid={localImage.childImageSharp.fluid} alt="img" />
        </div>
      </div>
      <div className="hide-md-xl">
        <div className={styles.bannerImage}>
          <Img fluid={localImage1.childImageSharp.fluid} alt="img" />
        </div>
      </div>
    </>
  );
};

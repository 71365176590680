import { graphql } from 'gatsby';
import React from 'react';
import Slider from 'react-slick';
import SliderSettings from '../SliderSettings/SliderSettings';
import styles from './infoPanel.module.scss';
import infoPanelIcon from './infoPanel-icons';
import Icon from '../../../helpers/icon';

export const infoPanelData = graphql`
  fragment infoPanelData on infoPanelBlock {
    id
    tag
    pageid
    blockid
    subBlock {
      discapacidad
      text
      icon
    }
  }
`;

const InfoPanelCard = ({ text, icon, discapacidad }) => {
  return (
    <>
      <div className={!discapacidad ? styles.normalCard : styles.reducedCard}>
        {/* <div className={styles.normalCard}> */}
        <Icon icon={icon} width="85" height="42" color="#5E5E5E" iconSet={infoPanelIcon} />
        <br />
        <span>{text}</span>
      </div>
    </>
  );
};

const InfoPanelContainer = ({ subBlock }) => {
  const gray = subBlock.filter(sub => sub.discapacidad === true);
  const white = subBlock.filter(sub => sub.discapacidad === false);

  return (
    <div className={styles.infoPanelContainer}>
      <div className="hide-md">
        <div className={styles.infoPanelDetailsDesktop}>
          {white.map(card => (
            <InfoPanelCard {...card} />
          ))}
        </div>
      </div>
      <div className="hide-md-xl">
        <div className={styles.infoPanelDetailsDesktop}>
          <Slider {...SliderSettings('oneItem')}>
            {white.map(card => (
              <InfoPanelCard {...card} />
            ))}
          </Slider>
        </div>
      </div>
      {gray.length > 0 ? (
        <>
          <div className="hide-md">
            <div className={styles.infoPanelDetailsGray}>
              {gray.map(card => (
                <InfoPanelCard {...card} />
              ))}
            </div>
          </div>
          <div className="hide-md-xl">
            <div className={styles.infoPanelDetailsGray}>
              <Slider {...SliderSettings('oneItem')}>
                {gray.map(card => (
                  <InfoPanelCard {...card} />
                ))}
              </Slider>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default InfoPanelContainer;

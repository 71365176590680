import React, { useState, useCallback } from 'react';
import { graphql, GraphQLSchema, GraphQLObjectType, GraphQLString, GraphQLNonNull } from 'gatsby';
import { BigImageCard } from '../src/components/Auto/BigImageCard/BigImageCard';
import HeaderBlock from '../src/components/Auto/HeaderBlock/HeaderBlock';
import Breadcrumb from '../src/components/Auto/Breadcrumb/Breadcrumb';
import DiscoverElements from '../src/components/Auto/DiscoverElements/DiscoverElements';
import Gallery from '../src/components/Auto/Gallery/Gallery';
import IconDescription from '../src/components/Auto/IconDescription/IconDescription';
import Map from '../src/components/Auto/Map/Map';
import { Cards } from '../src/components/Auto/Cards/CardsPanel/CardsPanel';
import { Filter } from '../src/components/Auto/Filter/Filter';
import { Tabs } from '../src/components/Auto/Tabs/Tabs';
import PageInfo from '../src/components/Auto/PageInfo/PageInfo';
import ImagesDiscover from '../src/components/Auto/ImagesDiscover/ImagesDiscover';
import SEO from '../src/helpers/seo';
import Layout from '../src/components/Layout/Layout';
import styles from './auto_pages.module.scss';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import InfoPanel from '../src/components/Auto/InfoPanel/InfoPanel';
import SingleParagraph from '../src/components/Auto/SingleParagraph/SingleParagraph';
import BigParagraphImages from '../src/components/Auto/BigParagraphsImages/BigParagraphsImages';
import TicketCard from '../src/components/Auto/TicketCards/TicketCards';
import { Banner } from '../src/components/Auto/Banner/Banner';
import RichMark from '../src/components/Auto/Richmark/Richmark';

// import './auto_pages.scss';

// export const GatsbyQuery = () => graphql`
export const GatsbyQuery = ({ data, pageContext: { locale, pageName } }) => graphql`
  query auto_pages($locale: String!, $pageName: String!) {
    allApBlockData(filter: { tag: { eq: $pageName }, lang: { eq: $locale } }) {
      nodes {
        id
        ... on jumbotronBlock {
          ...JumbotronData
        }
        ... on bigImageCardBlock {
          ...BigImgCard
        }
        ... on headerBlock {
          ...HeaderData
        }
        ... on DiscoverElementsBlock {
          ...discoverElementsBlock
        }
        ... on breadcrumbBlock {
          ...breadcrumbData
        }
        ... on iconDescriptionBlock {
          ...iconDescriptionBlock
        }
        ... on infoPanelBlock {
          ...infoPanelData
        }
        ... on mapBlock {
          ...MapData
        }
        ... on GalleryBlock {
          ...GalleryData
        }
        ... on CardsBlock {
          ...CardsData
        }
        ... on NewFilterBlock {
          ...FilterData
        }
        ... on TabsBlock {
          ...TabsData
        }
        ... on PageInfoBlock {
          ...PageInfoData
        }
        ... on ImagesDiscoverBlock {
          ...ImagesDiscoverData
        }
        ... on BigParagraphsImagesBlock {
          ...BigParagraphData
        }
        ... on TicketCardsBlock {
          ...TicketCardsData
        }
        ... on BlueArrowBlock {
          ...BlueArrowData
        }
        ... on RichMarkBlock {
          ...RichMarkData
        }
        ... on bannerBlock {
          ...BannerData
        }
        ... on SingleParagraphBlock {
          ...SingleParagraphData
        }
      }
    }
    allSeoBlock(filter: { tag: { eq: $pageName }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
          imgRich
        }
      }
    }

    # allMagniBlock(filter: { tag: { eq: $pageName }, lang: { eq: $locale } }) {
    #   edges {
    #     node {
    #       id
    #       tag
    #       pageid
    #       blockid
    #       right_link_copy
    #       right_link_path
    #       right_zone
    #       left_link_copy
    #       left_link_path
    #       left_zone
    #     }
    #   }
    # }

    # allSagaBlock(filter: { tag: { eq: $pageName }, lang: { eq: $locale } }) {
    #   edges {
    #     node {
    #       id
    #       tag
    #       pageid
    #       blockid
    #       textbox
    #       content_textbox
    #       title
    #       descripcion
    #       schedule
    #       video
    #       imagen
    #       pdftext
    #       filepdf
    #       cta1
    #       ctatext1
    #       cta2
    #       ctatext2
    #       size1
    #       color1
    #       size2
    #       color2
    #     }
    #   }
    # }
  }
`;

const blockDeclaration = {
  jumbotronBlock: () => {},
  headerBlock: (data) => {
    return <HeaderBlock {...data} key={data.id} />;
  },

  breadcrumbBlock: (data) => {
    return (
      <Breadcrumb
        data={data}
        locale={data.lang}
        pageName={data.tag}
        title={data.menuEntry}
        bg={data.bg}
        key={data.id}
      />
    );
  },
  iconDescriptionBlock: (data) => {
    return <IconDescription {...data} />;
  },
  DiscoverElementsBlock: (data) => {
    return <DiscoverElements {...data} />;
  },
  GalleryBlock: ({ subBlock }) => {
    return <Gallery data={subBlock} />;
  },
  mapBlock: (data) => {
    return <Map data={data} />;
  },
  BlueArrowBlock: () => {
    return <BlueArrow />;
  },
  infoPanelBlock: (data) => {
    return <InfoPanel {...data} />;
  },
  bigImageCardBlock: (data) => {
    return <BigImageCard {...data} />;
  },
  CardsBlock: (data, updateFilter, filters, clearState, setCount) => {
    return <Cards {...{ data, filters, setCount }} />;
  },
  NewFilterBlock: (data, updateFilter, filters, clearState, setCount, count) => {
    return <Filter {...{ data, updateFilter, clearState, count }} />;
  },
  TabsBlock: (data) => {
    return <Tabs {...data} />;
  },
  PageInfoBlock: (data) => {
    return <PageInfo {...data} />;
  },
  ImagesDiscoverBlock: (data) => {
    return <ImagesDiscover {...data} />;
  },
  BigParagraphsImagesBlock: (data) => {
    return <BigParagraphImages {...data} />;
  },
  TicketCardsBlock: (data) => {
    return <TicketCard {...data} />;
  },
  bannerBlock: (data) => {
    return <Banner {...data} />;
  },
  RichMarkBlock: (data) => {
    return <RichMark {...data} />;
  },
  SingleParagraphBlock: (data) => {
    return <SingleParagraph {...data} />;
  },
};

const AutoPage = ({ pageContext, data }) => {
  if (!data.allApBlockData.nodes) {
    return 1;
  }

  // Aquí nos defendemos de si alguna vez llega un bloque sin position
  // (le ponemos un numero alto para que salga último en este caso)
  const blocks = data.allApBlockData.nodes.map((b) => {
    const pos = Number(b.position);
    return { ...b, position: Number.isFinite(pos) ? pos : 1000 };
  });

  blocks.sort((a, b) => {
    if (a.position > b.position) {
      return 1;
    }
    if (a.position < b.position) {
      return -1;
    }
    return 0;
  });

  const jumboData = blocks.filter((block) => block.__typename === 'jumbotronBlock')[0];
  const [filters, setFilters] = useState({});
  const [count, setCount] = useState(0);
  const initialState = {};

  const updateFilter = useCallback(
    (filterId, value) => {
      setFilters({ ...filters, [filterId]: value });
    },
    [filters, setFilters]
  );

  const clearState = useCallback(() => {
    setFilters({ ...initialState });
  }, [filters, setFilters]);

  const renderB = (block) => {
    return blockDeclaration[block.__typename](
      block,
      updateFilter,
      filters,
      clearState,
      setCount,
      count
    );
  };

  return (
    <Layout locale={pageContext.locale} pageName={pageContext.pageName} jumboData={jumboData}>
      <SEO
        title={data.allSeoBlock.edges[0].node.title}
        description={data.allSeoBlock.edges[0].node.subtitle}
        pathname={pageContext.url}
        lang={pageContext.locale}
        imageRich={data.allSeoBlock.edges[0].node.imgRich}
      />
      <div className={styles.autoPagesBackground}>
        <div className={styles.autoPagesContent}>{blocks.map((block) => renderB(block))}</div>
      </div>
    </Layout>
  );
};

export default AutoPage;

import React from 'react';
import Slider from 'react-slick';
import { graphql } from 'gatsby';
import Icon from '../../../helpers/icon';
import SliderSettings from '../SliderSettings/SliderSettings';
import textSize from '../../../helpers/textSize';
import Buttons from '../Buttons/Buttons';
import IconSet from './IconDescription-icons';
import styles from './iconDescription.module.scss';

export const iconDescriptionBlock = graphql`
  fragment iconDescriptionBlock on iconDescriptionBlock {
    id
    title
    titleStyle
    titleSize
    subtitle
    blockid
    position
    subBlock {
      title_icon
      subtitle_icon
      icon
      cta
      ctaText
      ctaColor
      ctaSize
    }
  }
`;

const IconDescriptionCards = (card) => (
  <div className={styles.iconItem}>
    {card.icon && (
      <div className={styles.iconElement}>
        <Icon icon={card.icon} className={styles[card.icon]} color="#5E5E5E" iconSet={IconSet} />
      </div>
    )}
    <div className={styles.iconTitle}>{card.title_icon}</div>
    <p className={styles.iconSubtitleFirstItem}>{card.subtitle_icon}</p>
    <Buttons link={card.cta} color="naranja_transparente" size={card.color} text={card.ctaText} />
  </div>
);

const IconDescriptionContainer = ({ title, titleStyle, titleSize, subtitle, subBlock }) => {
  return (
    <div className={styles.iconDescriptionSection}>
      <a id="group" />
      <div className={styles.headerSection}>
        {textSize(title, titleStyle, titleSize)}
        {textSize(subtitle, 'div', 'headerSub')}
      </div>
      <div className={styles.cardsContent}>
        <div className={styles.cardsWrapperDesktop}>
          {subBlock.map((card) => (
            <IconDescriptionCards {...card} />
          ))}
        </div>
        <div className={styles.cardsWrapperMobile}>
          <Slider {...SliderSettings('oneItem')}>
            {subBlock.map((card) => (
              <IconDescriptionCards {...card} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default IconDescriptionContainer;

/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { graphql } from 'gatsby';
import textSize from '../../../helpers/textSize';
import styles from './SingleParagraph.module.scss';

export const SingleParagraphData = graphql`
  fragment SingleParagraphData on SingleParagraphBlock {
    id
    blockid
    pageid
    position
    title
    titleTag
    titleStyle
    titleAlign
    body
  }
`;

const SingleParagraph = ({ title, titleTag, titleStyle, titleAlign, body }) => {
  return (
    <div className={styles.SingleParagraphContainer}>
      <div className={styles.title} style={{ textAlign: `${titleAlign || 'center'}` }}>
        {textSize(title, titleTag, titleStyle)}
      </div>
      {body ? <div className={styles.body} dangerouslySetInnerHTML={{ __html: body }} /> : null}
    </div>
  );
};

export default SingleParagraph;

import React from 'react';
import { graphql } from 'gatsby';
import tt from '../../../helpers/translation';
import styles from './filter.module.scss';

export const FilterData = graphql`
  fragment FilterData on NewFilterBlock {
    blockid
    filterBy
    resources
    seeAll
    logo
    subBlock {
      position
      label
      values
      visibleValues
    }
  }
`;

const CreateForm = ({ filter: { label, values, visibleValues }, updateFilter }) => {
  const handleDropDownChange = event => {
    const index = event.nativeEvent.target.selectedIndex;
    const name = event.nativeEvent.target[index].value;
    const label1 = event.nativeEvent.target.name;
    updateFilter(label1, name);
  };

  const labelArray = values.split(' ');
  const valueArray = visibleValues.split(',');

  const options = labelArray.map((key, index) => ({ value: key, labelText: valueArray[index] }));

  return (
    <>
      <form className={styles.selectOption}>
        <select
          name={`${label}`}
          form={`select-${label}`}
          onChange={handleDropDownChange}
          defaultValue={label.replace(/_/g, ' ')}
        >
          <option hidden value={label.replace(/_/g, ' ')}>
            {label.replace(/_/g, ' ')}
          </option>
          {options.map(option => (
            <option key={option.value} value={`${option.value}`}>{`${option.labelText}`}</option>
          ))}
        </select>
      </form>
    </>
  );
};

const FilterBox = ({ data: { subBlock, resources }, updateFilter, clearState, count }) => {
  const handleResetFilter = () => {
    clearState();
  };

  return (
    <div className={styles.filterFormContainer}>
      <div className={styles.filterText}>{`${count} ${resources}`}</div>
      <div className={styles.filterForms}>
        {subBlock.map(filter => (
          <CreateForm {...{ filter, updateFilter }} />
        ))}
      </div>
      <button type="button" className={styles.filterClear} onClick={handleResetFilter}>
        X {tt('Borrar filtros', 'es')}
      </button>
    </div>
  );
};
export const Filter = ({ data, updateFilter, clearState, count }) => {
  return (
    <div className={styles.filterContainer}>
      <div className={styles.standardBox}>
        <div className={styles.filterImage}>
          <img src={data.logo} alt="logo" />
        </div>
        <FilterBox {...{ data, updateFilter, clearState, count }} />
      </div>
    </div>
  );
};

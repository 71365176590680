import React, { useState } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import PropTypes from 'prop-types';
import Buttons from '../../Buttons/Buttons';
import { Fenrir } from '../../../Fenrir/Fenrir';
import Icon from '../../../../helpers/icon';
import RatingStars from '../../../RatingStars/RatingStars';
import iconSet from '../Utils/freyacard-icons';
import styles from './cards.module.scss';
import textSize from '../../../../helpers/textSize';

const CheckButtons = ({
  ButtonsCard: { cta, color, ctaText, cta2, color2, ctaText2, size1, size2 },
  popUpContent,
  openMap,
}) => {
  return (
    <div className={styles.buttonCta}>
      {cta ? (
        <Buttons link={cta} color={color} size={size1} text={ctaText} blank={cta} />
      ) : // ) : popUpContent ? (
      //   <button onClick={openMap}>{ctaText}</button>
      null}
      {cta2 ? (
        <Buttons link={cta2} color={color2} size={size2} text={ctaText2} />
      ) : // ) : popUpContent ? (
      //   <button onClick={openMap}>{ctaText2}</button>
      null}
    </div>
  );
};

const CardVariableContent = ({ description, located, ButtonsCard, openMap, popUpContent }) => {
  return (
    <>
      {located ? (
        <div className={`${styles.subtitleCard} ${styles.locationFreya}`}>
          <Icon icon="location_on" width="22" height="22" color="black" iconSet={iconSet} />
          <span>{located}</span>
        </div>
      ) : null}
      {description ? (
        <div className={styles.subtitleCard}>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      ) : null}
      <CheckButtons openMap={openMap} {...{ ButtonsCard, popUpContent }} />
    </>
  );
};

const PopUpData = ({ onClose, additionalInfo }) => {
  return (
    <div className={styles.freyaPopup}>
      <div className={popupClose} onClick={onClose}>
        X
      </div>
      <div className={popupText}>
        <div dangerouslySetInnerHTML={{ __html: additionalInfo }} />
      </div>
    </div>
  );
};

const CardFixedBody = ({ title, titleTag, titleStyle, localImage, altImage }) => {
  return (
    <>
      <div className={styles.imgCard}>
        {localImage.childImageSharp ? (
          <Img
            className={styles.imgWraper}
            fluid={localImage.childImageSharp.fluid}
            alt={altImage}
          />
        ) : null}
      </div>
      <div className={styles.titleCard}>{textSize(title, titleTag, titleStyle)}</div>
    </>
  );
};
const CardContainer = (data) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className={`${styles.GlobalCard} ${styles[data.cardStyle]}`}>
        <CardFixedBody {...data} />
        <CardVariableContent
          {...data}
          openMap={() => {
            setOpen(true);
          }}
        />
        {isOpen ? <PopUpData onClose={() => setOpen(false)} {...data} /> : null}
      </div>
    </>
  );
};

CardContainer.propTypes = {
  data: PropTypes.object,
};

export default CardContainer;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import Slider from 'react-slick';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import textSize from '../../../helpers/textSize';
import SliderSettings from '../SliderSettings/SliderSettings';
import Buttons from '../Buttons/Buttons';
import styles from './imagesDiscover.module.scss';

export const ImagesDiscoverData = graphql`
  fragment ImagesDiscoverData on ImagesDiscoverBlock {
    id
    blockid
    pageid
    title
    title_tag
    title_size
    text
    cta
    cta_text
    cta_size
    cta_color
    bg_color
    position
    subBlock {
      alt
      image
      localImage {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

const ImagesDiscover = ({
  title,
  title_tag,
  title_size,
  text,
  cta,
  cta_text,
  cta_size,
  cta_color,
  bg_color,
  subBlock,
}) => {
  const mapSlider = array =>
    array.map(e => (
      <div className={styles.tabImageDiv}>
        <img
          alt={e.altImage}
          className={styles.tabImage}
          src={e.localImage ? e.localImage.childImageSharp.fluid.src : {}}
        />
      </div>
    ));

  return (
    <div
      className={styles.ImagesDiscoverContainer}
      style={{
        backgroundColor: `${bg_color || '#fff'}`,
      }}
    >
      {textSize(title, title_tag, title_size)}
      {textSize(text, 'div', 'headerSub')}
      <div className="hide-md">
        <div className={styles.itemContainer}>{mapSlider(subBlock)}</div>
      </div>
      <div className="hide-md-xl">
        <div className={styles.itemContainer}>
          <Slider {...SliderSettings('oneItem')}>{mapSlider(subBlock)}</Slider>
        </div>
      </div>
      <Buttons link={cta} size={cta_size} color="naranja_transparente" text={cta_text} />
    </div>
  );
};

export default ImagesDiscover;

/* eslint-disable react/jsx-props-no-spreading */
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';
import textSize from '../../../../helpers/textSize';
import styles from './discoverCard.module.scss';
import Buttons from '../../Buttons/Buttons';

/**
 * It's a box over a card on the top.
 * @property {ImageSharp} mainImage - Big image
 * @property {Image} image - Logo inside the circle.
 * @property {String} title - Card title
 * @property {String} titleTag - Title Tag
 * @property {String} titleStyle - Style desired for title
 * @property {HTML} body - Content of the card. Buttons and descriptions.
 */

const CheckButtons = ({ button1, button2 }) => (
  <div className={styles.buttonCont}>
    {button1.link ? <Buttons {...button1} /> : null}
    {button2.link ? <Buttons {...button2} /> : null}
  </div>
);

const DiscoverCards = ({
  localImage1,
  altLogo,
  altImage,
  localImage2,
  title,
  titleTag,
  titleStyle,
  description,
  buttons,
}) => {
  return (
    <div className={styles.discoverCardContiner}>
      <div className={styles.discoverCardImage}>
        <Img fluid={localImage1.childImageSharp.fluid} alt={altImage} />
      </div>
      <div className={styles.discoverCardBox}>
        <div className={styles.discoverCardLogo}>
          <Img
            fluid={localImage2.childImageSharp.fluid}
            alt={altLogo}
            className={styles.logo}
            placeholderStyle={{ position: 'relative' }}
          />
        </div>
        <div className={styles.discoverCardTitle}>{textSize(title, titleTag, titleStyle)}</div>
        <div className={styles.cardBody}>
          <p className={styles.description}>{description}</p>
          <CheckButtons {...buttons} />
        </div>
      </div>
    </div>
  );
};

export default DiscoverCards;

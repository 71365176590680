import React from 'react';
import { graphql } from 'gatsby';
import getRichMark from '../../../helpers/richmark';

export const RichMarkData = graphql`
  fragment RichMarkData on RichMarkBlock {
    id
    blockid
    pageid
    position
    richMarkText
  }
`;
const Richmark = props => {
  const { richMarkText } = props;
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: richMarkText }} />;
};

export default Richmark;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { graphql } from 'gatsby';
import Slider from 'react-slick';
import Img from 'gatsby-image/withIEPolyfill';
import SliderSettings from '../SliderSettings/SliderSettings';
import styles from './BigParagraphImages.module.scss';

export const BigParagraphData = graphql`
  fragment BigParagraphData on BigParagraphsImagesBlock {
    id
    blockid
    pageid
    firstParagraph
    secondParagraph
    thirdParagraph
    position
    subBlock {
      textInfo
      alt
      image
      localImage {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

const IconHeaderCard = ({ localImage, alt, textInfo }) => {
  return (
    <div className={styles.imageItem}>
      <Img fluid={localImage.childImageSharp.fluid} alt={alt} className={styles.iconDesc} />
      <span>{textInfo}</span>
    </div>
  );
};

const BigParagraphImages = ({ firstParagraph, secondParagraph, thirdParagraph, subBlock }) => {
  return (
    <div className={styles.BigParagraphImagesContainer}>
      <div className={styles.leftZone}>
        {subBlock ? (
          <>
            <div className={`${styles.iconContent} hide-md`}>
              {subBlock.map(card => (
                <IconHeaderCard {...card} />
              ))}
            </div>
            <div className={`${styles.iconContent} hide-md-xl`}>
              <Slider {...SliderSettings('oneItem')}>
                {subBlock.map(card => (
                  <IconHeaderCard {...card} />
                ))}
              </Slider>
            </div>
          </>
        ) : null}
        {firstParagraph ? (
          <div className={styles.textHtml} dangerouslySetInnerHTML={{ __html: firstParagraph }} />
        ) : null}
        {secondParagraph ? (
          <div className={styles.textHtml} dangerouslySetInnerHTML={{ __html: secondParagraph }} />
        ) : null}
      </div>
      {thirdParagraph ? (
        <div className={styles.rightZone}>
          <div className={styles.box} dangerouslySetInnerHTML={{ __html: thirdParagraph }} />
        </div>
      ) : null}
    </div>
  );
};

export default BigParagraphImages;

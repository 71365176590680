import React from 'react';
import styles from '../components/Auto/HeaderBlock/headerBlock.module.scss';
/**
 * Transform your text with tag and style desired.
 * @param  {string} text String that is going to be transformed.
 * @param  {string} tag String will have the size of the tag.
 * @param  {style} tag This is the classname that will be given to the text.
 * @return {HTMLElement} Will give you the text with tag.
 */
const textSize = (text, tag, size, pageid) => {
  const titleStyle = styles[`${size.toLowerCase()}Title`];

  if (text) {
    switch (tag.toUpperCase()) {
      case 'H1':
        return <h1 className={titleStyle}>{text}</h1>;
      case 'H2':
        // if ([731, 732, 733, 734].includes(pageid)) break;
        return <h2 className={titleStyle}>{text}</h2>;
      case 'H3':
        return <h3 className={titleStyle}>{text}</h3>;
      case 'H4':
        return <h4 className={titleStyle}>{text}</h4>;
      case 'H5':
        return <h5 className={titleStyle}>{text}</h5>;
      case 'H6':
        return <h6 className={titleStyle}>{text}</h6>;
      case 'DIV':
        return <div className={titleStyle} dangerouslySetInnerHTML={{ __html: text }} />;
      default:
        break;
    }
  }
};

export default textSize;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import Img from 'gatsby-image/withIEPolyfill';
import React, { useState } from 'react';
import Slider from 'react-slick';
import { graphql } from 'gatsby';
import SliderSettings from '../SliderSettings/SliderSettings';
import styles from './Gallery.module.scss';
import Modal from '../Widgets/ModalMap/ModalMap';

export const GalleryData = graphql`
  fragment GalleryData on GalleryBlock {
    id
    blockid
    pageid
    subBlock {
      alt
      image
      localImage {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

const MapSlider = ({ images, galSettings, updateFilter, openPhoto, filter, isOpen }) => {
  const handleClick = (props) => {
    updateFilter(props);
    openPhoto(true);
  };
  return (
    <Slider {...SliderSettings(galSettings, filter)}>
      {images.map((image, index) => {
        return (
          <div
            key={image.alt}
            className={styles.galleryCard}
            onClick={!isOpen && openPhoto ? handleClick.bind(this, index) : null}
          >
            <Img
              fluid={image.localImage.childImageSharp.fluid}
              alt={image.alt}
              className={styles.dualTest}
              placeholderStyle={
                isOpen
                  ? {
                      position: 'relative',
                      maxHeight: '70vh',
                    }
                  : {
                      position: 'relative',
                      width: '300px',
                      height: '300px',
                    }
              }
            />
          </div>
        );
      })}
    </Slider>
  );
};

const Gallery = ({ data }) => {
  const [isOpen, setOpen] = useState(false);
  const [filters, setFilters] = useState({});
  let settings = '';
  if (data.length > 4 && data.length <= 10) {
    settings = 'infinite';
  } else if (data.length > 10) {
    settings = 'tooLong';
  } else {
    settings = 'normal';
  }

  if (data.length > 1) {
    return (
      <div className={styles.topPawBody}>
        <div className={`${styles.galleryContainer} hide-mobile`}>
          <MapSlider
            images={data}
            isOpen={isOpen}
            galSettings="infinite"
            openPhoto={setOpen}
            updateFilter={setFilters}
          />
        </div>
        <div className={`${styles.galleryContainer} hide-sm`}>
          <MapSlider
            images={data}
            galSettings="infinite"
            openPhoto={null}
            isOpen={false}
            updateFilter={setFilters}
          />
        </div>
        {isOpen ? (
          <Modal onClose={() => setOpen(false)}>
            <div className={styles.galleryContainer}>
              <MapSlider
                isOpen={isOpen}
                images={data}
                galSettings="infinitieModal"
                filter={filters}
              />
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
  if (data.length === 1) {
    return (
      <div className={styles.topPawBody}>
        <div className={styles.simpleCard}>
          <Img fluid={data[0].localImage.childImageSharp.fluid} alt={data[0].alt} />
        </div>
      </div>
    );
  }
  return null;
};

export default Gallery;
